import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import {
  Control,
  FieldErrors,
  FormProvider,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { DataGuardPage } from "shared/model/data-collection/types-and-constants";
import { flattenDataGuardConfig } from "shared/model/data-collection/utils";

import { DataCollectionImplementationsRecord } from "./constants";
import { UpdateUserAttributesDto, User } from "~/api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "~/api/use-api";
import { GhostButton, PrimaryButton } from "~/components/form/button";
import { Form } from "~/components/form/form";
import { LoadingScreen } from "~/components/loading-screen";
import { useT } from "~/i18n/use-t";
import { ModalConfig, useModal } from "~/models/modal-provider";
import { queryClient } from "~/query-client";
import { useTenantConfig } from "~/tenant-settings";
import { ME_KEY } from "~/types/query-keys";
import { useTenantId } from "~/util/use-active-tenant-id";
import { useUser } from "~/util/use-user";

export const DataGuard: React.FC<PropsWithChildren> = ({ children }) => {
  const { showModal, hideModal, updateModal } = useModal();
  const modalIsOpen = useRef(false);

  const user = useUser();
  if (!user) {
    return <LoadingScreen />;
  }

  const { dataGuardConfig } = useTenantConfig();

  const flatDataGuardConfig = useMemo(
    () => flattenDataGuardConfig(dataGuardConfig),
    [dataGuardConfig],
  );

  const haveAllValuesBeenFilled = useMemo(
    () =>
      flatDataGuardConfig.every((input) =>
        DataCollectionImplementationsRecord[input.inputFor].wasCompleted(user),
      ),
    [flatDataGuardConfig, user],
  );

  useEffect(() => {
    if (haveAllValuesBeenFilled && modalIsOpen.current) {
      hideModal();
      modalIsOpen.current = false;
      return;
    }

    const modalConfig: ModalConfig = {
      className: "overflow-scroll",
      showsWindowCloseButton: false,
      closeOnClickOutside: false,
      showCloseButton: false,
      overflowVisible: false,
      children: <DataGuardModal user={user} />,
    };

    if (!haveAllValuesBeenFilled && !modalIsOpen.current) {
      showModal(modalConfig);
      modalIsOpen.current = true;
    }

    if (!haveAllValuesBeenFilled && modalIsOpen.current) {
      updateModal(modalConfig);
    }
  }, [haveAllValuesBeenFilled, hideModal, showModal, updateModal, user]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const DataGuardModal: React.FC<{ user: User }> = ({ user }) => {
  const tenantId = useTenantId();
  const formMethods = useForm<UpdateUserAttributesDto>({
    defaultValues: {
      ...user?.userAttributes,
      dateOfBirth: getYearFromDate(user?.userAttributes?.dateOfBirth),
    },
  });

  const { errors } = formMethods.formState;

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
      api.updateUserAttributes({
        updateUserAttributesDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    { successMessage: { tx: "profile.updateSuccessful" }, trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  return (
    <FormProvider {...formMethods}>
      <Form
        onSubmit={formMethods.handleSubmit((data: UpdateUserAttributesDto) => {
          updateUserAttributes({
            ...data,
            dateOfBirth: getDateFromYear(data.dateOfBirth),
          });
        })}
        className="h-full w-full"
      >
        <DataGuardModalPageContainer
          user={user}
          control={formMethods.control}
          register={formMethods.register}
          errors={errors}
        />
      </Form>
    </FormProvider>
  );
};

const DataGuardModalPageContainer: React.FC<{
  user: User;
  register: UseFormRegister<UpdateUserAttributesDto>;
  control: Control<UpdateUserAttributesDto>;
  errors: FieldErrors<UpdateUserAttributesDto>;
}> = ({ ...rest }) => {
  const { dataGuardConfig: pages } = useTenantConfig();
  const t = useT();

  const flatDataGuardConfig = useMemo(
    () => flattenDataGuardConfig(pages),
    [pages],
  );

  const firstIncompletePage = useMemo(
    () =>
      flatDataGuardConfig.find(
        (value) =>
          !DataCollectionImplementationsRecord[value.inputFor].wasCompleted(
            rest.user,
          ),
      )?.pageNumber ?? pages.length,
    [flatDataGuardConfig, pages.length, rest.user],
  );

  const [activePageIndex, setActivePageIndex] = useState(firstIncompletePage);

  useEffect(() => {
    setActivePageIndex(firstIncompletePage);
  }, [firstIncompletePage]);

  return (
    <div>
      <ul className="steps w-full">
        {pages.map((page, index) => (
          <li
            key={`dataguard-step-counter-${index}`}
            className={`${index <= activePageIndex ? "step-primary" : ""} step`}
          >
            {t({ tx: page.title })}
          </li>
        ))}
      </ul>
      {pages.map((page, index) =>
        index === activePageIndex ? (
          <DataGuardModalPage
            key={`dataguard-component-${index}`}
            page={page}
            {...rest}
          />
        ) : null,
      )}
      <div className="flex flex-row items-center justify-center gap-2">
        {activePageIndex > 0 && (
          <GhostButton
            className="w-20"
            tx="navigateBack"
            onClick={() => {
              setActivePageIndex(() =>
                activePageIndex > 0 ? activePageIndex - 1 : 0,
              );
            }}
          />
        )}

        <PrimaryButton
          className="w-40"
          tx="general.confirm"
          onClick={() => {
            if (activePageIndex + 1 <= firstIncompletePage)
              setActivePageIndex(() =>
                activePageIndex < pages.length - 1
                  ? activePageIndex + 1
                  : pages.length - 1,
              );
          }}
        />
      </div>
    </div>
  );
};

const DataGuardModalPage: React.FC<{
  page: DataGuardPage;
  user: User;
  register: UseFormRegister<UpdateUserAttributesDto>;
  control: Control<UpdateUserAttributesDto>;
  errors: FieldErrors<UpdateUserAttributesDto>;
}> = ({ page, ...rest }) => {
  const components = useMemo(
    () =>
      page.dataGuardInputs.map(
        (dataGardInput) =>
          DataCollectionImplementationsRecord[dataGardInput.inputFor].Component,
      ),
    [page],
  );

  return (
    <div className="my-4 w-full">
      {components.map((Component, index) => (
        <Component key={`dataguard-component-${index}`} {...rest} />
      ))}
    </div>
  );
};

const getYearFromDate = (date?: string) => {
  return date ? new Date(date).getFullYear().toString() : undefined;
};

const getDateFromYear = (year?: string) => {
  return year ? new Date(Number(year), 0, 1).toISOString() : undefined;
};
