import { useCallback, useMemo } from "react";

import { ChatTutorial } from "./chat/chat-tutorial";
import { Dropdown, DropdownProps } from "./form/dropdown";
import { useAuthStore } from "../auth/auth-store-context";
import { useModal } from "../models/modal-provider";
import { useProfile } from "../profile/profile";
import { useTenantConfig } from "../tenant-settings";

enum Settings {
  PROFILE = "PROFILE",
  TUTORIAL = "TUTORIAL",
  SIGN_OUT = "SIGN_OUT",
}

export const SettingsDropdown: React.FC<{
  isMobileView?: boolean;
}> = ({ isMobileView }) => {
  const { showProfile } = useProfile();
  const { signOut } = useAuthStore();
  const { showModal } = useModal();

  const { hideTutorial } = useTenantConfig();

  const toggleModal = useCallback(() => {
    showModal({
      showCloseButton: false,
      showsWindowCloseButton: true,
      windowCloseButtonClassName: "text-bg-blue-900 border-0",
      className: "max-w-xs",
      children: <ChatTutorial />,
    });
  }, [showModal]);

  const options: DropdownProps<Settings>["options"] = useMemo(
    () =>
      hideTutorial
        ? [
            {
              value: Settings.PROFILE,
              label: { tx: "profile.headerTitle" },
            },

            {
              value: Settings.SIGN_OUT,
              label: { tx: "auth.signOut.signOutButton" },
            },
          ]
        : [
            {
              value: Settings.PROFILE,
              label: { tx: "profile.headerTitle" },
            },
            {
              value: Settings.TUTORIAL,
              label: { tx: "profile.tutorial" },
            },
            {
              value: Settings.SIGN_OUT,
              label: { tx: "auth.signOut.signOutButton" },
            },
          ],
    [hideTutorial],
  );

  const optionsActionMap: Partial<Record<Settings, () => void>> = useMemo(
    () =>
      hideTutorial
        ? {
            [Settings.PROFILE]: showProfile,
            [Settings.SIGN_OUT]: signOut ?? (() => {}),
          }
        : {
            [Settings.PROFILE]: showProfile,
            [Settings.TUTORIAL]: toggleModal,
            [Settings.SIGN_OUT]: signOut ?? (() => {}),
          },
    [hideTutorial, showProfile, toggleModal, signOut],
  );

  const onChangeDropdownValue = useCallback(
    (value: Partial<Settings>) => {
      optionsActionMap[value]?.();
    },
    [optionsActionMap],
  );

  return (
    <Dropdown
      noValueSelected={{ tx: "general.settings" }}
      options={options}
      onChange={onChangeDropdownValue}
      className={isMobileView ? "dropdown-top" : undefined}
    />
  );
};
