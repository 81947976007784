import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FrontendPageEnum } from "shared/model/pages";
import { SocialMediaMedia } from "shared/types/social-media-media";

import {
  DeleteSocialMediaPostRequest,
  LikeSocialMediaPostRequest,
  SocialMediaPostMedia,
  SocialMediaPost as SocialMediaPostType,
} from "../../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import { SOCIAL_MEDIA_MEDIA } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { ActionMenu, ActionMenuAction } from "../form/action-menu";
import { GhostButton } from "../form/button";
import { SvgIcon } from "../icons/svg-icon";
import { Text } from "../text";

export const SocialMediaPost: React.FC<{
  post: SocialMediaPostType;
  onOpenPostDetail: (postId: string) => void;
  onPostLike: (postId: LikeSocialMediaPostRequest) => void;
  onPostDelete: (postId: DeleteSocialMediaPostRequest) => void;
  isAdmin?: boolean;
}> = ({
  post,
  onPostLike,
  onPostDelete,
  onOpenPostDetail,
  isAdmin = false,
}) => {
  const navigate = useNavigate();
  const tenantId = useTenantId();
  const { showModal, hideModal } = useModal();

  const { data: media } = useApiQuery(
    "backend",
    (api) =>
      api.getSocialMediaPostMedia({
        socialMediaPostIdDto: { postId: post.id },
      }),
    SOCIAL_MEDIA_MEDIA(post.id),
  );

  const handleOpenPostDetail = useCallback(() => {
    onOpenPostDetail(post.id);
  }, [onOpenPostDetail, post.id]);

  const actions = useMemo<ActionMenuAction[]>(() => {
    return [
      {
        label: { tx: "socialMedia.actions.delete" },
        action: () => {
          showModal({
            title: { tx: "socialMedia.areYouSureYouWantToDelete" },
            onConfirm: () => {
              onPostDelete({ id: post.id, ...toTenantIdHeader(tenantId) });
              hideModal();
            },
          });
        },
      },
      {
        label: { tx: "socialMedia.actions.edit" },
        action: () => {
          navigate(
            FrontendPageEnum.ADMIN_SOCIAL_MEDIA_EDIT.replace(":id", post.id),
          );
        },
      },
    ];
  }, [hideModal, navigate, onPostDelete, post, showModal, tenantId]);

  return (
    <div className="card-compact card self-stretch justify-self-stretch bg-base-100 pb-4 shadow-xl">
      <SocialMediaPostContent
        post={post}
        media={media}
        onImageClick={handleOpenPostDetail}
      />
      <SocialMediaPostButtons
        {...post}
        onPostLike={onPostLike}
        onCommentClick={handleOpenPostDetail}
      />
      {isAdmin && (
        <ActionMenu className="absolute right-5 top-5" actions={actions} />
      )}
    </div>
  );
};

const SocialMediaPostContent: React.FC<{
  onImageClick: () => void;
  post: SocialMediaPostType;
  media?: SocialMediaPostMedia;
}> = ({ onImageClick, post, media }) => {
  return (
    <div className="flex h-full cursor-pointer flex-col" onClick={onImageClick}>
      <div className="relative">
        {media?.mediaType === SocialMediaMedia.CAROUSEL && (
          <div className="absolute right-2 top-2 w-14 rounded-md">
            <SvgIcon icon="stack" />
          </div>
        )}
        <img className="rounded-md" src={media?.mediaUrls[0]} />
      </div>
      <div className="p-3">
        <div className="line-clamp-4 overflow-hidden">
          {post?.body.split("\n").map((segment, index) => (
            <p key={index}>{segment}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

const SocialMediaPostButtons: React.FC<{
  id: string;
  liked: boolean;
  likes: number;
  comments: number;
  onPostLike: (postId: LikeSocialMediaPostRequest) => void;
  onCommentClick: () => void;
}> = ({ id, liked, likes, comments, onPostLike, onCommentClick }) => {
  return (
    <div className="mt-3 flex justify-around">
      <GhostButton
        icon="heart"
        iconColor={liked ? "fill-error" : "fill-neutral"}
        onClick={() => {
          onPostLike({ socialMediaPostIdDto: { postId: id } });
        }}
      >
        <Text text={String(likes)} />
      </GhostButton>
      <GhostButton icon="chat" onClick={onCommentClick}>
        <Text text={String(comments)} />
      </GhostButton>
    </div>
  );
};
