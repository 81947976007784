import React, { useCallback } from "react";
import { twMerge } from "tailwind-merge";

import { UserMediaRecord } from "../../../api/generated/multiagent";
import { MimeMainType } from "../../../types/mime-main-type";
import { Text } from "../../text";
import { ChatMessage, ChatRole } from "../types";

export type ChatMessageProps = {
  message: ChatMessage;
  userChatColors?: { bubbleColor?: string; textColor?: string };
} & JSX.IntrinsicElements["div"];

export const ChatMessageComponent: React.FC<ChatMessageProps> = ({
  message: { role, content, media },
  className,
  userChatColors,
  ...rest
}) => {
  const handleMessageWithSources = useCallback(
    (message: string): React.ReactNode => {
      const splitMessageAtFirstSource = message.split(/\n\n\[\d+\]/, 2);
      const maybeSeparator = message.match(/\n\n\[\d+\]/);
      const separator = maybeSeparator ? maybeSeparator[0] : "[1]";
      const firstSeparator = separator.split(/\n\n/)[1];

      if (splitMessageAtFirstSource.length >= 2) {
        return (
          <div className="collapse">
            <div className="text-wrap collapse-title w-full text-mama-default-primary">
              {splitMessageAtFirstSource[0] || " "}
              <MessageDocument media={media as UserMediaRecord[]} />
              <Text
                className="flex flex-row items-center justify-start py-2 font-bold"
                tx="chat.mamaAnswerSources"
              />
            </div>
            <input type="checkbox" />
            <div className="text-wrap collapse-content break-all text-mama-default-primary">
              {`${firstSeparator}${splitMessageAtFirstSource[1]}`}
            </div>
          </div>
        );
      }
    },
    [media],
  );

  return (
    <div
      className={twMerge(
        "chat",
        role === ChatRole.USER
          ? "chat-end"
          : role === ChatRole.ASSISTANT
          ? "chat-start"
          : "mb-2 flex justify-center",
        className,
      )}
      {...rest}
    >
      {role === ChatRole.USER ||
      (content.text && content.text.split(/\n\n\[\d+\]/).length === 1) ? (
        <Text
          as="div"
          style={
            role === ChatRole.USER && !!userChatColors
              ? {
                  backgroundColor: userChatColors.bubbleColor,
                  color: userChatColors.textColor,
                }
              : {}
          }
          className={twMerge(
            "whitespace-pre-line",
            role === ChatRole.USER
              ? "chat-bubble chat-bubble-primary"
              : role === ChatRole.ASSISTANT
              ? "chat-bubble bg-mama-gray-100"
              : "w-full  rounded-xl bg-chat-bubble-assistant text-black",
          )}
          txComponents={{}}
          text={content.text || " "}
        >
          <MessageDocument media={media as UserMediaRecord[]} />
        </Text>
      ) : (
        <div
          className={twMerge(
            "w-fit whitespace-pre-wrap",
            role === ChatRole.ASSISTANT
              ? "chat-bubble bg-mama-gray-100"
              : "w-full max-w-[calc(100%-1.6rem)] rounded-xl bg-chat-bubble-assistant  text-black",
          )}
        >
          {handleMessageWithSources(content.text || " ")}
        </div>
      )}
    </div>
  );
};

const MessageDocument: React.FC<{
  media?: UserMediaRecord[];
}> = ({ media }) => {
  return media?.length ? (
    <div className="flex flex-wrap justify-center gap-2 py-2">
      {media?.map((m, idx) =>
        m.mainType === MimeMainType.IMAGE ? (
          <img
            className="h-full max-h-48 rounded-xl object-cover"
            key={`${m.path}-${idx}`}
            src={m.path}
          />
        ) : m.mainType === MimeMainType.AUDIO ? (
          <audio key={`${m.path}-${idx}`} controls src={m.path} />
        ) : null,
      )}
    </div>
  ) : null;
};
