import { useRef } from "react";
import { twMerge } from "tailwind-merge";

import { Button, GhostButton } from "./button";
import { useDetailsBlur } from "../../util/hooks";
import { IconType } from "../icons/svg-icon";
import { I18nProps } from "../text";

export type ActionMenuAction = {
  label: I18nProps;
  action: () => void;
  isEnabled?: boolean;
};

export type ActionMenuProps = {
  actions: ActionMenuAction[];
  icon?: IconType;
} & JSX.IntrinsicElements["details"];

export const ActionMenu = ({
  actions,
  icon = "ellipsis",
  className,
  ...rest
}: ActionMenuProps): ReturnType<React.FC> => {
  const containerRef = useRef<HTMLDetailsElement>(null);

  useDetailsBlur(containerRef);

  return (
    <details
      {...rest}
      className={twMerge("dropdown-end dropdown", className)}
      ref={containerRef}
    >
      <Button as="summary" className="btn-square" icon={icon} />

      <ul className="dropdown-content menu mt-2 rounded-box bg-base-100 p-2 shadow">
        {actions
          .filter(({ isEnabled = true }) => isEnabled)
          .map((action, i) => (
            <GhostButton
              key={i}
              onClick={() => {
                action.action();
                if (containerRef.current?.open) {
                  containerRef.current.open = false;
                }
              }}
              className="m-1 whitespace-nowrap"
              {...action.label}
            />
          ))}
      </ul>
    </details>
  );
};
